<template>
    <div class="wrapper">
                <headBack>
                    <template v-slot:title>
                        <div class='title'>
                            查看报告
                        </div>
                    </template>
                </headBack>
                <div class='header_cont'></div>
                <div class="time">
                    <div>检测日期:{{times.inspectionDate}}</div>
                    <div>报告日期:{{times.outputReportDate}}</div>
                </div>
                    <div
                            class="img_box"
                    >
                        <div class="box"
                             v-for="(item,index) in imgList2.target"
                        >
                            <div
                                    v-if="item.indexOf('.pdf') != -1"
                                    @click="showPdf(item)"
                                    class="pdf_box"
                            >
                                <img src="https://static.yihu365.cn/img/h5Img/assets/img/order/pdf.jpg" alt="">
                                <div>点击下载预览</div>
                            </div>
                            <van-image
                                    v-else
                                    class="img"
                                    fit="contain"
                                    width="100px"
                                    :src="item"
                                    @click="imgView(item)"
                            />

                        </div>
                    </div>

        <!--        pdf预览-->
                <van-overlay :show="show">
                    <iframe  @click="show = false"  class="iframe" :src="pdfSrc" frameborder="0" scrolling="no"></iframe>
                </van-overlay>
    </div>
</template>

<script  type="module">
    import {useRouter, useRoute} from 'vue-router'
    import homeApi from '@axios/home'
    import {useStore} from 'vuex'
    import {ImagePreview } from 'vant'
    import {ref,reactive} from 'vue';
    export default ({
        name: "pdfList",
        setup () {
            const store = useStore()
            const route = useRoute()
            const imgList2 = reactive([]);
            const show = ref(false)
            const pdfSrc = ref('')

            const times = reactive({
                inspectionDate:'',
                outputReportDate:'',
            });

            const getFileList = () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    source: "android_health_102",
                    sid: " 80001100001",
                    _lan: "4.9E-324",
                    orderId: route.query.orderId,//订单号
                     //orderId: "7223858",//订单号
                    _lon: "4.9E-324",
                    token: store.state.token,
                    timeStamp: "2707505",
                    function: 'reportList',
                    needLogin: true,
                    version: store.state.version,
                    _cityCode: "0",
                    login_userId_base:store.state.uid + '',
                    newversion: store.state.newversion,
                    standardFlag: "0",
                }))
                formData.append('encryption', false)

                homeApi.getList(formData).then((res) => {
                    const imgList = res.data.images.split(',')
                    imgList2.target= imgList.map((item)=> 'http://file.yihu365.cn' + item);
                    times.outputReportDate = res.data.outputReportDate;
                    times.inspectionDate = res.data.inspectionDate;
                    console.log(imgList.target)
                })
            }
             getFileList();

            const imgView = (item) => {
                ImagePreview({
                    images:[item],
                    showIndex:false,
                    loop:false
                });
            }
            const showPdf = (item) => {
                pdfSrc.value =item;
                show.value =true;
            }
            return {
                showPdf,
                imgView,
                imgList2,
                times,
                show,
                pdfSrc
            }
        }
    })
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .back_cont{
        z-index: 100!important;
    }

    .wrapper{

        font-size: 28px;
        ::v-deep(.van-overlay){
            z-index: 10000;
        }
        .iframe{
            width: 100%;
            height: 100%;
        }
        .time{
            display: flex;
            justify-content: space-between;
            padding:20px 40px;
        }
        .img_box{
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            padding: 30px 0;
            .box{
                width: 200px;
                height: 400px;
                margin-bottom: 40px;
                margin-left: 30px;
                overflow: hidden;
                .pdf_box{
                    text-align: center;
                    color:#666666;
                    img{
                        width: 90%;
                    }
                }
                iframe{
                    width: 100%;
                    overflow-y: hidden;
                    overflow-x: hidden;
                }
                img{
                    width: 100%;
                }
            }
            .tu{
                margin-left: 30px;
            }
        }
        .btn{
            display: flex;
            justify-content: center;
        }
    }
</style>
